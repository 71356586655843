.tabs__navigation {
  position: relative;
  display: inline-block;
}

.tabs__select-label {
  color: $color-blue;
  font-weight: bold;
  margin-bottom: 1rem;
}

.tabs__select-button {
  cursor: pointer;
  font-weight: bold;
  height: 5rem;
  overflow: hidden;
  padding-right: 3.5rem;
  position: relative;
  text-align: left;
  text-overflow: ellipsis;
  transition-property: box-shadow, color;
  white-space: nowrap;
  width: 100%;
  font-size: $font-size-xxs;
  padding-left: 15px;
  background-color: $color-gray-light-bg;

  .t-inverted & {
    background-color: $color-green-medium;
    color: #fff;
  }

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    background-color: $color-green-light;
    border-radius: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
  }

  &:hover {
    text-decoration: underline;
  }

  &[aria-expanded='true'] {
    .tabs__select-icon {
      transform: rotate(180deg);
      transform-origin: center;
      top: 9px;
    }
  }
}

.tabs__select-icon {
  font-size: 3rem;
  line-height: 0;
  position: absolute;
  right: 2rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-green-dark;
  fill: $color-green-dark;
  z-index: 2;
  width: 10px;
}

.tabs__list {
  @include list-reset();
}

.tabs__list-link {
  color: $color-green-dark;
  display: block;
  font-weight: 500;
  position: relative;
  font-size: $font-size-xxs;
  margin-right: 50px;

  .t-inverted & {
    color: #fff;
  }

  @include mq($from: s) {
    &[aria-selected='true'] {
      cursor: default;
    }

    &:not([aria-selected='true']):hover {
      //text-decoration: underline;
    }
  }
}

.tabs--full-width {
  .tabs__navigation {
    width: 100%;
  }
}

.tabs--list {
  .tabs__select {
    display: none;
  }

  .tabs__list {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 2px solid $color-greenwhite-dark;

    .t-inverted & {
      border-color: $color-green-medium;
    }
  }

  .tabs__list-item {
    align-items: flex-end;
    display: flex;
  }

  .tabs__list-link--active::after {
    background-color: $color-green-light;
    bottom: -22px;
    content: '';
    display: block;
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}

.tabs--menu {
  .tabs__navigation {
    width: 100%;
  }

  .tabs__select-button[aria-expanded='true'] {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tabs__list {
    background-color: $color-gray-light-bg;
    border-top: 0;
    left: 0;
    min-width: 100%;
    padding: 1rem 0;
    position: absolute;
    top: 100%;
    z-index: 3;

    .t-inverted & {
      background-color: $color-green-medium;
    }
  }

  .tabs__list-link {
    background-image: none;
    padding: 0.5rem 1.5rem;
    margin-bottom: 15px;

    // stylelint-disable

    &:not(.tabs__list-link--active):hover,
    &:not(.tabs__list-link--active):focus {
      text-decoration: underline;
    }

    // stylelint-enable
  }

  .tabs__list-link--active {
    cursor: default;
    font-weight: bold;
  }
}

.tabs__content {
  margin-top: fluid-size(40px, 85px);
}
