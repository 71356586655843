/* stylelint-disable */
.craft-cookie-consent {

  .elc-cookie {
    margin-top: 3rem;
  }

  #elc-cookie-consent {
    font-size: $font-size-xxs;
    line-height: $line-height-default;
    z-index: z('cookie-consent');

    @include mq($until: l) {
      max-width: calc(100% - 2em);
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @extend .headline;
    }

    h3 {
      @extend .headline--5;
    }

    button {
      @extend .button;
      width: auto;
    }

    .elc-primary-heading {
      margin-bottom: 1.5rem;
    }

    .elc-text-link {
      text-transform: none;
      color: inherit;
      font-size: $font-size-xs-mobile;
      font-weight: $font-weight-bold;
    }

    .button-container {
      margin-top: 2rem;
    }

    &.elc-small,
    &.elc-fullwidth {
      @include mq($until: l) {
        width: 100% !important;
      }
    }

    .elc-cookie-checkbox {
      /* reset native checkbox */
      appearance: none;
      background-color: $color-green-light;
      margin: 0;

      display: grid;
      place-content: center;

      font: inherit;
      color: $color-green-dark;
      width: 1.8rem;
      height: 1.8rem;
      border: 0.15em solid $color-green-light;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
    }

    .elc-cookie-checkbox::before {
      content: '';
      width: 0.8em;
      height: 0.8em;
      transform: scale(0);
      //transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em green;

      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    .elc-cookie-checkbox:checked::before {
      transform: scale(1);
    }
  }

  #elc-cookie-tab {
    background-color: $color-green-medium;
  }

  .elc-cookie-group {
    margin-top: 2rem;
  }
}
/* stylelint-enable */
